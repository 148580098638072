import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { FC, memo } from 'react'

import { Link } from '@headless/ui'
import data from './data'

import { StyledMenu } from './styled'

interface NavProps {
  categories: any[]
}

const PcNav: FC<NavProps> = ({ categories }) => {
  console.log(categories)

  const options: HTMLReactParserOptions = {
    replace: (node: any) => {
      const classes = node.attribs?.class
      if (classes && classes.split(' ').indexOf('mobile') > -1) {
        return <></>
      }

      if (node.name === 'img') {
        node.attribs.src = node.attribs['data-src']
      }

      if (node.name === 'a') {
        const match: string = node.attribs.href.match(/.com(\S*)/)?.[1] ?? ''
        const href: string = match.replace(/\/\//g, '/')

        return (
          <Link
            href={href}
            className={node.attribs?.class ?? ''}
            title={node.attribs?.title ?? ''}
          >
            {domToReact(node.children, options)}
          </Link>
        )
      }
    }
  }

  return (
    <StyledMenu>
      {data.length > 0 && (
        <ul className="nav__menu">
          {data.map((category: any, index) => {
            return (
              <li className="nav__column" key={category.id + index}>
                <div className={`nav__item nav__item__${category.id}`}>
                  {parse(category.content, options)}
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </StyledMenu>
  )
}

export default memo(PcNav)
