const data = [
  {
    content:
      '<a href="https://www.zurin.com/women-s-athletic-and-sneakers-zr-en" class="level0"><span>Athletic&Sneakers</span></a>',
    id: 1
  },
  {
    content:
      '<a href="https://www.zurin.com/boots-booties-zr-en" class="level0"><span>Boots</span></a>',
    id: 2
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-clogs-zr-en" class="level0"><span>Clogs</span></a>',
    id: 3
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-flats-zr-en" class="level0"><span>Flats</span></a>',
    id: 4
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-loafers-zr-en" class="level0"><span>Loafers</span></a>',
    id: 5
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-mules-zr-en" class="level0"><span>Mules</span></a>',
    id: 6
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-sandals-zr-en" class="level0"><span>Sandals</span></a>',
    id: 7
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-slippers-zr-en" class="level0"><span>Slippers</span></a>',
    id: 8
  },
  {
    content:
      '<a href="https://www.zurin.com/women-s-shoes-zr-en" class="level0"><span>All Shoes</span></a>',
    id: 9
  }
]

export default data
